
.react-datepicker-time__input input[type="time"] {
    border-color: $input-border-color;
    border-width: $input-border-width;
    border-radius: $input-border-radius;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;
    line-height: 30px;
    padding-left: 5px;
    padding-right: 5px;
}
.react-datepicker-time__input {
    margin-left: 0 !important;
}
.input-container, .react-datepicker__input-container,.react-select {
    .react-select__placeholder {
        margin-left: 6px;
    }
    input.input,input[type="text"],textarea,.react-select__control {
        border-color: $input-border-color;
        border-width: $input-border-width;
        padding-left: 0;
        border-radius: $input-border-radius;
        background-color: $input-background-color !important;
        opacity: 1 !important;
        width: 100%;
    }
}
.input-container, .react-datepicker__input-container {
    width: 100%;
    input.input,input[type="text"],textarea {
        padding-left: 10px!important;

        height: $input-height;
    }
}
.avatar {
    background-color: #F0F0F0;
    color: #5858DF;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 25px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.large {
        font-size: 55px;
        width: 160px;
        height: 160px;
        border-radius: 80px;
    }
}
.react-datepicker {
    background-color: #fff;
    border: none !important;
    border-radius: $base-border-radius !important;
    box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.10);
    display: flex !important;
    &__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
        background-color: $link-color !important;
        color: white !important;
    }
    &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 95px !important;
    }
    &__month-container {

    }
    &__time-container {
        //border-left: 1px solid $lavender !important;
        margin-left: 15px !important;
    }

    &__header {
        background-color: transparent !important;
        border-bottom: none !important;
        border-top-left-radius: $base-border-radius !important;
        border-top-right-radius: $base-border-radius !important;
    }
    &__navigation--next {
        border-left-color: $link-color-light !important;
    }
    &__navigation--previous {
        border-right-color: $link-color-light !important;
    }
    &__day--selected {
        background-color: $link-color-light !important;
        color: white !important;
    }
    &__triangle {
        display: none;
    }
    &__current-month {
        //color: $oxford-blue !important;
    }
    &__day-name {
        //color: $light-steel-blue !important;
    }
    &__day {
        //color: $oxford-blue;
    }
    &__day--today {
        color: white !important;
    }
}

select {
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    cursor:pointer;
}

.dropdown-menu {
    width: max-content;
}
.link-light {
    color: $link-color-light;
}
.link-lg {
    font-size: 20px;
}
.link-primary {
    color: $link-primary;
    font-size: 16px;
}
label.small {
    a {
        font-weight: bold;
    }
}

.check {
    cursor: pointer;
    display: inline-block;

    img {
        pointer-events: none;
        position: absolute;
        top:7.5px;
        left:5px;
        z-index: 2;
    }
    .rc-checkbox-input {
        position: absolute;
        cursor: pointer;

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
    }
    .rc-checkbox .rc-checkbox-inner {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        width: 25px;
        height: 25px;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        border-color: #09E9D4;
        background-color: #ffffff;
        transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }
    .rc-checkbox {
        position: relative;
        top: 0;
    }
    .rc-checkbox-checked .rc-checkbox-inner {
        background-color: #09E9D4;

    }
}
.form-group {
    position: relative;
}
.input-container-prepend {
    position: relative;
    input {
        text-indent: 12px;
    }
    .prepend-icon {
        z-index: 2;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 10px;
        font-weight: bold;
        color: $text-muted;
    }
}
.form-group.small {
    label {
        font-size: 22px;
        margin-bottom: 0;
    }
    .input-container, .react-datepicker__input-container {
        height: 38px;
        line-height: 38px;
        .input, input[type="text"] {
            height: 38px;
        }
        textarea.input {
            height: auto;
        }
    }
}

.pointer-none {
    pointer-events: none !important;
}
.full-width {
    width: 100%;
}
.border-radius {
    &__base {
        border-radius: $base-border-radius !important;
    }
}

.opacity {
    &__600 {
        opacity: 0.6;
    }
}

.navbar {
    height: 90px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
}
.dropdown-menu {
    position: absolute;

    width: 300px !important;
    right: 0px;
    top: 20px;
    background: #FFFFFF;
    box-shadow: -1px 1px 10px 2px rgba(160, 160, 160, 0.25);
    border-radius: 8px;
    padding: 0;
}

.dropdown-header {
    margin: 23px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height:166px;
    text-align: center;
    border-radius: 8px;
    h3 {
        color: #5858DF;
        font-size: 20px;
        font-weight: normal;
        font-weight: normal;
    }
}

.profile-form {
    max-width: 700px;
}

.org-form {
    max-width: 750px;
}

.carousel {
    @include transition(transform 400ms ease);
    flex-wrap: nowrap;

    &__controls{
        button{
            padding-left:0;
            padding-right:0;
            border-left:none;
            border-right:none;
        }
    }
}

.office {
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 415px;
    width: 350px;
 background-color: rgba(141, 224, 213, 0.26);
    .input-container {
        line-height: 45px;
        input.input {
            border: 1px solid #979797;
            background-color: transparent !important;
            height: 40px;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.radio {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #09E9D4;
    img {
        width:20px;
        opacity: 0;
    }
    &.active {
        background-color: #09E9D4;
        img {
            opacity: 1;
        }
    }
}

a.danger {
    color: $danger;
    font-size: 16px;
    text-decoration: underline;
}

.dropdown {
    position: absolute;
    right: 330px;
    top: 20px;

    font-weight: bold;
    img {
        margin-right: 10px;
    }

}

.subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #f1f1f1;
    color: $text-muted;
    width: 196px;
    height: 30px;
    &.standard {
        background-color: rgba(101, 194, 215, 0.1);
        color: #65C2D7;
    }
    &.premium {
        background-color: rgba(188, 188, 242, 0.4);
        color: #000;
    }

}
.bg-primary {
    background-color: $primary !important;
}


.plan-card {
    font-family: "Raleway";
    color: #666666;
    box-shadow: 0px 12px 40px rgba(59, 59, 59, 0.12);
    border-radius: 8px;
    padding: 70px;
    height: 800px;
    width: 500px;
    background-color: white;
    border-radius: 8px;
    .name {
        font-family: "Raleway";
        text-align: center;
        font-weight: 600;
        font-size: 20px;
    }
    .currency {
        font-family: "Raleway";
        color: #3B3B3B;
        font-weight: 300;
        font-size: 24px;
        margin-right: 20px;
    }
    .frequency {
        margin-left: 10px;
        margin-top: 24px;
        font-family: "Raleway";
        color: #3B3B3B;
        font-weight: 300;
        font-size: 24px;
        margin-right: 26px;
        display: inline-block;
    }
    .price {
        font-family: "Raleway";
        color: #3B3B3B;
        font-weight: 700;
        font-size: 48px;
    }
}

.paywall {
    overflow-x: hidden;
    padding-left:60px;
    padding-right:60px;
    h1 {
        font-size: 48px;
    }
    p {
        font-size: 16px;
        color: white;
    }
}

.full-height {

        margin-bottom: 20px;
    .carousel-container {
        height: 800px;
    }
}

.card {
    hr {
        margin-top: 0;
        margin-bottom: 20px;
    }
    h2 {
        text-align: left;
        color: #294B85;
        font-size: 28px;
        margin-bottom: 0;
        padding-bottom: 0;;
    }
    a {
        font-size: 22px;
    }
}
.card-1 {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding:42px;

}

.settings-icon {
    width: 40px;
    background-color: #09E9D4;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    display: flex;
}

.ukraine {
    background-color: #65C2D7;
    height: 109px;
    display: flex;
    align-items: center;
    color: white;
    a {
        color: white !important;
        text-decoration: underline !important;
    }
    img {
        height:66px;
        margin-right: 40px;
    }
}

.text-primary {
    color: $primary-opaque !important;
}


.text-blue {
    color: $text-blue;
}

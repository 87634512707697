@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";

* {
  font-family: 'DM Sans', sans-serif;
}

// COLORS
$brand-dark-blue: rgba(88, 189, 196, 1);
$primary-opaque: rgba(88, 189, 196, 1);
$primary: rgba(88, 189, 196, 0.9);
$premium: #5858DF;
$premium-translucent: rgba(88, 88, 223, 0.7);
$brand-primary: rgba(88, 189, 196, 0.9);
$brand-secondary: #2C2733;
$text: #333;
$text-muted: #9A9AB0;
$text-blue: rgba(55, 162, 186, 1);
$link-color: $text-blue;
$link-color-light: #1AC0C6;

// Border Radius
$base-border-radius: 8px;

//Input component variables
$danger: #FF000099;
$alert-danger-border: #c70000;
$input-height: 48px;
$input-height-lg: 62px;
$input-line-height: 48px;
$input-font-size: inherit;
$input-font-weight: 200;
$input-border-color: #65C2D7;
$input-background-color: #fff;
$input-border-radius: 8px;
$input-border-width: 1px;
$input-placeholder-color: rgb(224, 224, 224);
$input-border-highlight-color: rgb(0, 188, 212);
$btn-border-radius: 8px;
$link-primary: #5858DF;


//Panel component variables
$panel-heading-height: 50px;
$panel-bg: #fff;
$panel-heading-bg: #f7f7f7;
$panel-heading-color: #333;
$panel-heading-border: 1px solid #f1f1f1;
$panel-icon-color: #36a39c;
$panel-icon-border-color: #ddd;
$panel-icon-background-color: #fff;
$panel-alt-heading-bg: #36a39c;
$panel-alt-heading-color: #fff;
$large-border-radius: 12px;

//Tab component styles
$tab-background: transparent;
$tab-background-hover: transparent;
$tab-background-active: #1AC0C6;
$tab-active-background: #1AC0C6;
$tab-inactive-color: lighten(black, 20);
$tab-active-color: white;
$tab-line-color: #1AC0C6;

//Toast component styles
$toast-bg: rgba(0,0,0,.9);
$toast-color: white;
$red-error: #CE2815;

// CSS Custom Properties
:root {
  --brand-dark-blue: #{$brand-dark-blue};
  --primary-opaque: #{$primary-opaque};
  --primary: #{$primary};
  --premium: #{$premium};
  --premium-translucent: #{$premium-translucent};
  --brand-primary: #{$brand-primary};
  --brand-secondary: #{$brand-secondary};
  --text: #{$text};
  --text-muted: #{$text-muted};
  --text-blue: #{$text-blue};
  --link-color: #{$link-color};
  --link-color-light: #{$link-color-light};
  --red-error: #CE2815;

  // Border Radius
  --base-border-radius: #{$base-border-radius};

  // Input component variables
  --danger: #{$danger};
  --alert-danger-border: #{$alert-danger-border};
  --input-height: #{$input-height};
  --input-height-lg: #{$input-height-lg};
  --input-line-height: #{$input-line-height};
  --input-font-size: #{$input-font-size};
  --input-font-weight: #{$input-font-weight};
  --input-border-color: #{$input-border-color};
  --input-background-color: #{$input-background-color};
  --input-border-radius: #{$input-border-radius};
  --input-border-width: #{$input-border-width};
  --input-placeholder-color: #{$input-placeholder-color};
  --input-border-highlight-color: #{$input-border-highlight-color};
  --btn-border-radius: #{$btn-border-radius};
  --link-primary: #{$link-primary};

  // Panel component variables
  --panel-heading-height: #{$panel-heading-height};
  --panel-bg: #{$panel-bg};
  --panel-heading-bg: #{$panel-heading-bg};
  --panel-heading-color: #{$panel-heading-color};
  --panel-heading-border: #{$panel-heading-border};
  --panel-icon-color: #{$panel-icon-color};
  --panel-icon-border-color: #{$panel-icon-border-color};
  --panel-icon-background-color: #{$panel-icon-background-color};
  --panel-alt-heading-bg: #{$panel-alt-heading-bg};
  --panel-alt-heading-color: #{$panel-alt-heading-color};
  --large-border-radius: #{$large-border-radius};

  // Tab component styles
  --tab-background: #{$tab-background};
  --tab-background-hover: #{$tab-background-hover};
  --tab-background-active: #{$tab-background-active};
  --tab-active-background: #{$tab-active-background};
  --tab-inactive-color: #{$tab-inactive-color};
  --tab-active-color: #{$tab-active-color};
  --tab-line-color: #{$tab-line-color};

  // Toast component styles
  --toast-bg: #{$toast-bg};
  --toast-color: #{$toast-color};
}

$theme-colors-rgb: (
  "primary": #fff,
  "secondary": #fff,
);

//Scaffolding
$breakpoints: (
  mobile-small: 320px,
  mobile: 375px,
  handheld-device: 576px,
  tablet: 768px,
  tablet-large: 992px,
  desktop: 1200px
);
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "3rdParty/index";
@import "components/index";
@import "flexbox/index";
@import "project/index";

.clickable {
  cursor: pointer;
}

.select, .react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container {
    position: relative;
    select {
        width: 100%;
        font-size: 16px;
        text-indent: 5px;
        outline: none;
        height: 38px;
        background: #fff;
        border: 1px solid $input-border-color;
        border-radius: $base-border-radius;
        padding-left: 5px;
        padding-right: 5px;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        cursor:pointer;
    }
    &__icon {
        pointer-events: none;
        position: absolute;
        top: 9px;
        right: 9px;
    }
    &__text {
        display: block;
        color: $text;
        letter-spacing: 0;
        margin-bottom: 0;
    }
}

.react-datepicker__month-dropdown-container, .react-datepicker__year-dropdown-container  {
    top: -2px;
    &::after {
        content: "⌄";
        position: absolute;
        top: 0px;
        font-size: 12px;
        right: 10px;
    }
    select {
        width: 100px;
        padding-right: 15px;
        height: 24px;
        font-size: 11px;
    }
}

.react-datepicker__year-dropdown-container,  {
    select {
        width: 60px;
    }
}

@import "../variables";

.subscription-table {
display: flex;
    justify-content: center;
}

td.subscription-type-cell {
    text-align: center;
}
td.cell-title {
    padding-left: 10px;
}

th.section-title {
    font-weight: 700;
    font-size: 18px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.subscription-type {
    display: flex;
    flex-direction: column;
    align-items: center;
}

td.subscription-block {
    display: flex;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
}

.title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #666666;
}

.type {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #3B3B3B;
}

.bottomText {
    font-family: 'DM Sans';
    font-size: 14px;
    color: #666666;
}

.price-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.currency {
    font-size: 18px;
    color: #3B3B3B;
}

.frequency {

    font-size: 16px;
    color: #3B3B3B;
    padding-left: 4px;
    padding-right: 4px;
}

.vat {
    font-size: 12px;
    color: #666666;
    align-self: flex-end;
}

.more-info {
    font-size: 10px;
    color: #666666;

}

.subscription-buy-btn {
    width: 200px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #333333;
    margin-right: 10px;
    margin-left: 10px;
}

.premium-button {
    background-color: #5858DF;
    color: #fff
}
td.bottom-row {
    padding-top: 40px;
    padding-bottom: 20px;
    margin-top: 20px

}

.review-root-container {
    h1 {
        color: #122D5C;
        text-align: center;
    }

    .review-box {
        box-sizing: border-box;
        width: 38.9vw;
        border: 2px solid #58BDC4;
        border-radius: 8px;
        padding: 34px;
        padding-left: 56px;


        .review-text {
            font-size: 20px;
            margin-top: 10px;
        }

        .button-container {

                justify-content: flex-end;

            .button {
                background-color: #65C2D7 !important;
            }

            .responded-btn {
                background-color: #65c2d740;
                color: #294B85 !important;
                pointer-events: none;
            }
        }

        .response-container {
            background: rgba(41, 75, 133, 0.2);
            border-radius: 8px;
            padding: 10px;
            margin-top: 20px;

            .response-title {
                font-size: 16px;
                color: #122D5C;
                justify-content: flex-end;
            }
        }

        .stick {
            margin-top: 10px;
            margin-bottom: 18px;
            margin-right: 10px;
            border: 4px solid rgba(0, 0, 0, 0.3);
            border-radius: 4px;
        }

        .response-form {
            display: flex;
            color: #294B85;
            flex-direction: column;

            .form-title-row {
                display: flex;
                flex: 1;
                justify-content: space-between;
                padding-right: 10px;
                margin-bottom: 8px;
            }

            .client-name-container {
                padding: 6px;
                padding-left: 10px;
                width: 20.78vw;
                border: 1px solid #8DE0D5;
                border-radius: 8px;
            }

            .response-input-box {
                box-sizing: border-box;
                width: 33.2vw;
                height: 167px;
                background: #FFFFFF;
                border: 1px solid #8DE0D5;
                border-radius: 8px;
                padding: 10px
            }

            .response-send-btn {
                align-self: center;
                margin-top: 10px;
                background-color: #65C2D7 !important;
            }
        }
    }
}

.back-container {
    color: $text-blue;
    padding-left: 6.35vw;
}
@import "../variables";

.tabs-nav {
	display: flex;
	flex-direction: row;
	background-color: $tab-background;
	button,button.btn,button.btn-primary {
		box-shadow: none;
		flex:1;
		background-color: transparent;
		border: 1px solid $tab-active-background;
		color: $tab-inactive-color !important;
		&:hover {
			background-color: $tab-background-hover;
		}
		&:active, &:focus {
			outline: 0;
			border: none;
			box-shadow: none !important;
			-moz-outline-style: none;
			background-color: $tab-background-active !important;
		}
		font-weight: 400;
		min-height: 34px;
		&.active {
			font-weight: 500;
		}
		&.tab-active {
			background: $tab-active-background;
			color: $tab-active-color !important;
		}
	}
	.btn-tab {
		border-radius: 0;
	}
}

.tabs-content {
	margin-top: 1em;
}

.tab-line {
	bottom: 0px;
	display: block;
	height: 2px;
	margin-top: -2px;
	position: relative;
	transition: left 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	background-color: $tab-line-color;
}
.tab-icon {
	margin-right: $input-padding-x;
}
.tab-item {
	display: none;
	&.tab-active {
		display: block;
	}
}

@import '../variables';

// Base font styles
body {
  font-family: 'DM Sans', sans-serif;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: $text;
}

// Headings
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: $text;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

// Paragraph
p {
  margin-bottom: 1rem;
}

// Links
a {
  color: $link-color;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: $link-color-light;
  }
}

// Muted text
.text-muted {
  color: $text-muted;
}

// Font weights
.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

// Text alignment
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Text transforms
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

// Custom text colors
.text-primary {
  color: $brand-primary;
}

.text-secondary {
  color: $brand-secondary;
}

.text-blue {
  color: $text-blue;
}

// Input placeholder
::placeholder {
  color: $text-muted;
  font-weight: 100;
}

// Label styles (similar to your input.scss)
label {
  font-size: 25px;
  text-align: left;
  color: $text-blue;
}
.auth-container {
  height: calc(100vh - 200px);
  >.row {
    height: 100%;
  }
  .auth-form {
    width: 100%;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.condensed {
      width: 400px;
      align-items: stretch;
    }
  }
  h1 {
    padding-bottom: 36px;
    color: $text-blue;
    font-weight: 500;
    font-size: 50px;
  }
  .auth-aside {
    min-height: 100%;
    background-color: #8DE0D5;
    img {
      max-height: 100%;
      max-width: 80%;
    }
  }
}

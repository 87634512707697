.cs-container {
    box-sizing: border-box;
    width: 41.6vw;
    height: 500px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: #294B85;
    padding: 0.83vw;
    display: flex;
    flex-direction: column;

    .cs-title-row {
        display: flex;
        justify-content: space-between;
    }

    .cs-title-text {
        font-weight: 700;
        font-size: 20px;
    }

    .cs-main-container {
        padding-left: 2.60vw;
            padding-right: 3.33vw;
    }

    &-divider {
        width: 33.33vw;
        height: 0px;
        border: 1px solid #8DE0D5;
        margin-bottom: 6px;
        margin-top: 4px;
    }

    &-label {
        padding-bottom: 6px;
        padding-top: 6px;
        font-weight: 700;
    }

    &-text-input {
        box-sizing: border-box;
        width: 33.22vw;
        height: 132px;
        border: 1px solid #8DE0D5;
        border-radius: 8px;
        margin-top: 6px;
        padding-left: 0.93vw;
            padding-right: 0.93vw;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .col-container {
        flex-direction: column;
        display: flex;
    }

    .cs-file-upload-box {
        box-sizing: border-box;
        width: 33.22vw;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #8DE0D5;
        border-radius: 8px;
        align-items: center;
        display: flex;
        padding: 12px;
        justify-content: space-between
    }

    .cs-send-btn {
        align-self: center;
        margin-top: 14px;
        background-color: #65C2D7;
    }


}
.close-circle-btn {
    border: 0;
    background-color: transparent;
}

.close-circle-btn:focus {
    outline: none;
}
.cs-popup {
    background-color: white;
    position: absolute;
    left: 9vw;
    margin-top: 7.81vw;
}

.invisible-btn {
    border: 0;
    background-color: transparent;

    &:focus {
        outline: none
    }
}
@import '../variables';

textarea {
  width: 100%;
}
.react-datepicker__input-container, .input-container {
  font-size: $input-font-size;
  line-height: $input-line-height;
  display: inline-block;
  position: relative;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border: 1px solid transparent;

  &.invalid .input {
    border-color: $alert-danger-border;
  }
  &.form-control {
    &.invalid {
      border-color: $alert-danger-border;
    }
  }

  input.input, input[type="text"] {
    &::placeholder {
      color: $text-muted;
    }
    background-color: #f1f1f1;
    border-width: $input-border-width;
    border-style: solid;
    outline: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    background-image: none;

    &:read-only {
      color: #777;
    }
  }

  input {
    font-weight: $input-font-weight;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0px;
    padding-left: 10px;
    position: relative;
    width: 100%;
    border: none;
    border-width: 0;
    outline: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    background-image: none;
    color: rgba(0, 0, 0, 0.870588);
    &::-webkit-input-placeholder {
      font-weight: 100;
    }
  }

  hr {
    border-bottom-width: 1px;
    border-style: none none solid;
    border-color: $input-border-color;
    bottom: 8px;
    box-sizing: content-box;
    margin: 0px;
    position: absolute;
    width: 100%;
    &.highlight {
      border-bottom-width: 2px;
      border-color: $input-border-highlight-color;
      transform: scaleX(0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }
  }

  &.error {
    input {
      background-color: $alert-danger-border;
    }
  }

  &.focused {
    input {
      border: 1px solid $brand-primary;
    }
  }
}
label {
  font-size: 25px;
  text-align: left;
  color: $text-blue;
}

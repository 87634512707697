.appointment {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10;

  &__tab {
    width: 11.45vw;
    min-width: 150px;
    height: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    font-weight: 700;
    border: none;
    font-size: larger;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }
  .bookings-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    width: 50.5vw;
  }

  &__tab:focus {
    outline: 0;
  }

  &__card {
    box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.1);
    border-radius: 18px;
    padding: 2em;
  }

  &__titleContainer {
    flex: 1;
    // justify-content: space-between;
  }

  &__date {
    color: #555;
    font-size: 12px;
  }

  &__notes {
    font-size: 14px;
    color: "#979797";
  }

  &__chip {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  &__btn {
    width: 205px;
    height: 50px;
    margin-bottom: 25px;
  }

  &__example-text {
    font-size: 80px;
    position: absolute;
    transform: rotate(-7.06deg);
    left: 28%;
    bottom: 30%;
  }

  .image-expand {
    width: 100px;
    border-radius: 5px;
  }

  &__PENDING {
    background-color: #ffb8001a;
  }

  &__COMPLETE {
    background-color: #1976d2;
  }

  &__ACCEPTED {
    background-color: #6bac7a;
    color: white;
  }

  &__DECLINED {
    background-color: rgba(203, 68, 74, 0.3);
  }

  &__EXPIRED {
    background-color: #f4f4f4;
  }
  &__locked {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .lock-round {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #58bdc4;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .locked-text {
      font-size: 20px;
      font-weight: 700;
      color: #58bdc4;
      margin: 0 30px 0 18px;
    }
    .purchase-btn {
      background-color: #58bdc4;
      padding: 8px 26px;
      border-radius: 5px;
      color: white;
    }
    .callType-container {
      background-color: #58bdc4;
      align-items: center;
      font-weight: 700;
      padding: 4px;
      color: "white" !important;
      width: 120px;
    }
  }
}

.decline-modal-container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 47.8vw;
  height: 29vh;
  min-height: 250px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 32px;
  box-shadow: 0 6px 13px 0 rgba(52, 48, 144, 0.1);

  .description {
    font-size: 18px;
    display: block;
    color: #9a9ab0;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;

    .btn {
      width: 28%;
      margin-right: 28px;
    }

    .accept-btn {
      color: white;
    }

    .decline-btn {
      color: #58bdc4;
    }
  }
}

.reschedule-modal {
  &__close-btn {
    display: flex;
    justify-content: flex-end;
  }

  &__available-time-container {
    overflow-y: scroll;
    height: 100%;
    .datepicker-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .datePicker-box {
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        &__picker {
          display: flex;
          align-items: center;
          border: 0 !important;
          .input {
            width: 146px;
            border: 0 !important;
          }
          .btn {
            border: 0 !important;
          }
        }
      }
    }
    .time-slot {
      margin-top: 16px;
      .divider {
        height: 1px;
        width: 100%;
        background-color: #65c2d7;
      }
    }
  }
}

.time-slot-container {
  .divider {
    height: 1px;
    width: 100%;
    background-color: #65c2d7;
  }

  .confirm-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 2.75rem;
    border-radius: 0.5rem;
    padding: 0rem 0.25rem;
    margin: 0.125rem 0rem;
    background-color: #65c2d7;

    .confirm-btn {
      font-size: 1.125rem;
      align-self: center;
      border-radius: 0.5rem;
      color: "black";
      font-weight: 500;
      padding: 0.125rem 2rem;
      margin: 0.125rem 0rem;
      background-color: white;
      border: 0;
    }
  }

  .available-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    height: 2.75rem;

    .available-btn {
      padding: 0.125rem 3rem;
      background-color: #65c2d7;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 500;
      align-self: center;
      border-radius: 0.5rem;
      border: 0;
    }
  }
}

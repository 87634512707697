p {
  color: $text;
  font-size: 25px;
  &.small {
    font-size: 18px;
  }
}

.nav-user {
  color: #5858DF;
  font-size: 19px;
}

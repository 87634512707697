.btn {
  border-radius: 10px;
  height: 40px;

  border-color: transparent;
  font-size: 16px;
  font-weight: bold;

  &.btn-lg {
    height: 48px;
    font-size: 20px;
  }
}
$btn-secondary: #8de0d5;
$btn-primary: rgba(9, 233, 212, 1);
$btn-outline: #58bdc4;
.btn-primary {
  background-color: $btn-primary;
  color: white !important;
  &:hover {
    background-color: lighten($btn-primary, 10);
  }
&:active,
&:focus {
    background-color: lighten($btn-primary, 20) !important;
  }

  &:disabled {
    background-color: $btn-primary;

    opacity: 0.5;
  }
}

.btn-outline-primary {
  background-color: $btn-primary;
  color: white !important;

  &:hover {
    background-color: lighten($btn-primary, 10);
  }
&:active,
&:focus {
    background-color: lighten($btn-primary, 20) !important;
  }

  &:disabled {
    background-color: $btn-primary;

    opacity: 0.5;
  }
}
.btn-outline {
  background-color: white;
  border-color: $btn-outline;
  color: #58BDC4 !important;

  &:hover {
    border-color: lighten($btn-outline, 10);
  }

  &:active,
  &:focus {
    border-color: lighten($btn-outline, 20) !important;
  }

  &:disabled {
    border-color: $btn-outline;
    opacity: 0.5;
  }
}
.btn-secondary {
  background-color: $btn-secondary  !important;
    color: $text-blue  !important;
  &:hover {
    background-color: darken($btn-secondary, 10);
  }
&:active,
&:focus {
    background-color: darken($btn-secondary, 20) !important;
  }

  color: $text-blue;
}

.btn-rounded {
  border-radius: 20px;
  background-color: #20bfe7;
  color: white;

  &:hover {
    color: white;
    background-color: darken(#20bfe7, 10);
  }

  &.dark {
    background-color: #5858df;
    &:hover {
      background-color: darken(#5858df, 10);
    }
  }

  font-weight: 300;
  padding-left: 20px;
  padding-right: 20px;
}